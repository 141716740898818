<template>
  <div class="table-box">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    <el-dialog title="查看失败原因" :visible.sync="dialogVisible" width="720px" top="25vh" :close-on-click-modal="false">
      <div class="content">
        <div class="content-row"><span>开锁时间：</span><span>{{failureObj.createdDate}}</span></div>
        <div class="content-row"><span>开锁状态：</span><span>{{failureObj.unLockStateStr}}</span></div>
        <div class="content-row failureReason">
          <div>失败原因：</div>
          <div style="width:540px;">{{failureObj.failureReason}}</div>
        </div>
        <div class="content-row failureReason">
          <div>详细日志：</div>
          <div class="flowLog">{{failureObj.flowLog}}</div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="closeFun()">关闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { utimes } from 'fs';

  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [{
              key: "date",
              label: "起止日期",
              timeType: "yyyy-MM-dd HH:mm:ss",
              format: "yyyy-MM-dd",
              type: "daterange"
            },
            {
              key: "unLockMethod",
              label: "开锁方式",
              type: "select",
              opt: {
                list: [],
              }
            }
          ],
          columns: [{
              label: "序号",
              key: "sequenceNumber",
              width: 80
            },
            {
              label: "开锁方式",
              key: "unLockMethodStr"
            },
            {
              label: "开锁人",
              key: "unLockUserStr"
            },
            {
              label: "电话号码",
              key: "unLockUserPhoneStr"
            },
            {
              label: "锁版本号",
              key: "lockVersion"
            },
            {
              label: "门锁电量",
              key: "batteryPower" 
            },
            {
              label: "开锁时间",
              key: "createdDate" 
            },
            {
              label: "手机品牌/型号",
              key: "phoneInfo"
            },
            {
              label: "微信版本号",
              key: "wechatVersion"
            },
            {
              label: "客户端平台/操作系统/版本",
              key: "terraceInfo",
              width: 200,
            },
            {
              label: "开锁状态",
              key: "unLockStateStr",
              type: "actionStatus",
              opt: {
                ativeStatus: "开锁失败",
                on(row, item) {
                  _this.dialogVisible = true;
                  _this.failureObj = {
                    createdDate: '',
                    unLockStateStr: '',
                    failureReason: '',
                    flowLog: '',
                  }
                  _this.failureObj.createdDate = row.createdDate;
                  _this.failureObj.unLockStateStr = row.unLockStateStr;
                  _this.failureObj.failureReason = row.failureReason;
                  _this.failureObj.flowLog = row.flowLog || '--';
                }
              }
            }
          ],
          exportButtons: [
          {
            type: "Export",
            url: this.EXport + `${_this.cache.get('lockTypeVal')==2?'/device-service/lockmanagment/getUnLockRecordList/cabinet/export':'/device-service/lockmanagment/getUnLockRecordList/platform/export'}`,
            listName: `云数${_this.cache.get('lockTypeVal')==2?'柜锁' : '平台锁'}_开门日志_${moment(new Date()).format("YYYYMMDD")}`,
            dto: {
              mac: this.$route.params.mac,
              lockId: this.$route.params.id,
              bindLockTime: this.cache.get("lockBindTime"),
            }
          },
        ],
        },
        roomList: [],
        dialogVisible: false,
        failureObj: {
          createdDate: '',
          unLockStateStr: '',
          failureReason: '',
          flowLog: '',
        },
        lockBindTime: '',
      };
    },
    created() {
      console.log("doorOpeningLog created!!");
      this.userInfo = this.cache.getLS("userInfo");
      if(this.userInfo.roleType != '1') {//非超级管理员不展示导出
         this.opt.exportButtons = []
      }

    },
    activated() {
      console.log("doorOpeningLog activated!!");
      if (this.$route.params.lockType == 2) {
        this.opt.search.forEach(item => {
          if (item.key == 'unLockMethod') {
            item.opt.list = [{
                label: "在线蓝牙开锁",
                value: "1"
              },
              {
                label: "刷卡卡开锁",
                value: "9"
              },
              {
                label: "人脸识别开锁",
                value: "3"
              },
              {
                label: "蓝牙公钥开锁",
                value: "6"
              },
              {
                label: "指纹开锁",
                value: "30"
              },
              {
                label: "扫码开锁",
                value: "31"
              },
              {
                label: "机械钥匙开锁",
                value: "101"
              },
              {
                label: "其他蓝牙开锁",
                value: "100"
              },
            ]
          }
        })
      } else {
        this.opt.search.forEach(item => {
          if (item.key == 'unLockMethod') {
            item.opt.list = [{
                label: "未知",
                value: "0"
              },
              {
                label: "在线蓝牙开锁",
                value: "1"
              },
              {
                label: "机械钥匙开锁",
                value: "101"
              },
              {
                label: "人脸识别开锁",
                value: "3"
              },
              {
                label: "离线蓝牙开锁",
                value: "4"
              },
              {
                label: "远程开锁",
                value: "5"
              },
              {
                label: "L2公钥开锁",
                value: "6"
              },
              // {
              //   label: "L2+密码开锁",
              //   value: "7"
              // },
              {
                label: "L2+公钥开锁",
                value: "8"
              },
              {
                label: "IC卡开锁",
                value: "9"
              },
              {
                label: "密码开锁",
                value: "10"
              },
              {
                label: "特殊开门事件",
                value: "11"
              },
              {
                label: "网关远程开锁",
                value: "14"
              },
              {
                label: "远程开锁",
                value: "15"
              },
              {
                label: "手动闭锁",
                value: "16"
              },
              {
                label: "单次远程开锁",
                value: "17"
              },
              {
                label: "单次远程闭锁",
                value: "18"
              },
              {
                label: "系统远程开锁",
                value: "19"
              },
              {
                label: "系统远程闭锁",
                value: "20"
              },
              {
                label: "指纹开锁",
                value: "30"
              },
              {
                label: "扫码开锁",
                value: "31"
              },
              {
                label: "其他蓝牙开锁",
                value: "100"
              },
            ]
          }
        })
      }
      this.userInfo = this.cache.getLS("userInfo");
      if(this.userInfo.roleType != '1') {//非超级管理员不展示导出
         this.opt.exportButtons = []
      }else {
        this.opt.exportButtons[0].dto = {
              mac: this.$route.params.mac,
              lockId: this.$route.params.id,
              bindLockTime: this.cache.get("lockBindTime"),}
      }     
    },
    mounted() {

    },
    methods: {
      onGet(opt) {
        console.log(opt.searchForm);
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          mac: this.$route.params.mac,
          lockId: this.$route.params.id,
          bindLockTime: this.cache.get("lockBindTime"),
          beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
          endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
          unLockMethod: opt.searchForm["unLockMethod"] ?
            opt.searchForm["unLockMethod"] : null
        };
        this.post("/device-service/lockmanagment/getUnLockRecordList", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.unLockMethodStr = [
              "未知",
              "在线蓝牙开锁",
              "机械钥匙开锁",
              "人脸识别开锁",
              "离线蓝牙开锁",
              "远程开锁",
              "L2公钥开锁",
              "",
              "L2+公钥开锁",
              "IC卡开锁",
              "密码开锁",
              "特殊开门事件",
              "",
              "",
              "网关远程开锁",
              "远程开锁",
              "手动闭锁",
              "单次远程开锁",
              "单次远程闭锁",
              "系统远程开锁",
              "系统远程闭锁"
            ][item.unLockMethod];
            if(item.unLockMethod == 100) {
              item.unLockMethodStr = '其他蓝牙开锁';
            } else if(item.unLockMethod == 101) {
              item.unLockMethodStr = '机械钥匙开锁';
            } else if(item.unLockMethod == 30) {
              item.unLockMethodStr = '指纹开锁';
            }else if(item.unLockMethod == 31) {
              item.unLockMethodStr = '扫码开锁';
            }
            item.unLockStateStr = ["开锁成功", "开锁失败"][item.unLockState - 1];
            if(item.unLockState == 2 && !item.failureReason) {
              item.unLockStateStr = "开锁失败 "
            }
            if(item.brand && item.model) {
              item.phoneInfo = item.brand + ' ' + item.model;
            }else {
              item.phoneInfo = '--';
            }
            if(item.platform && item.operSys) {
              item.terraceInfo = item.platform + ' ' + item.operSys;
            }else {
              item.terraceInfo = '--';
            }
            item.wechatVersion = item.wechatVersion ? item.wechatVersion : '--';
            
            if (item.unLockState == 2) {
              item.failureFlag = 0; //开锁失败客点击查看失败原因
            } else if (item.unLockState == 1) {
              item.failureFlag = 1;
            }

            item.date = this.format(item.createdDate);
            
            if(item.unLockState == "2") {
                item.batteryPower = '--';
            }else {
                item.batteryPower = item.batteryPower + "%";
            }
            item.unLockUserStr = item.unLockUser ? item.unLockUser : "--";
            item.lockVersion = item.lockVersion || '--';
            if (this.cache.getLS("userInfo")["roleType"] != '8') {
              item.unLockUserPhoneStr = item.unLockUserPhone ?
                item.unLockUserPhone.substring(0, 3) +
                "****" +
                item.unLockUserPhone.substring(7, item.unLockUserPhone.length) :
                "--";
            } else {
              item.unLockUserPhoneStr = item.unLockUserPhone;
            }
            if (item.unLockState == "2") {
              item.bk = 2;
            } else {
              item.bk = 0;
            }
          }
          opt.cb(res.data);
        });
      },
      closeFun() {
        this.dialogVisible = false;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .table-box {
    height: 620px;
  }


  .content {
    padding-left: 39px;
  }

  .content-row {
    padding-bottom: 24px;
  }

  .failureReason {
    display: flex;
  }

  .flowLog {
    display: flex;
    width: 540px;
    max-height: 200px;
    overflow: auto;
    margin-bottom: 10px;
  }

  .dialog-footer {
    text-align: center;
  }
</style>